'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { addToWaitlist } from '@/services/api/waitlist'
import type React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function CTADark() {
	const { t } = useTranslation('landing_page')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setLoading(true)
		try {
			const response = await addToWaitlist(email)
			setMessage(response.message)
			setEmail('')
		} catch (error: any) {
			setMessage(error.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{/* Mobile Version: displayed on small screens */}
			<div className='block md:hidden'>
				<div className='w-full flex justify-center my-8 px-4'>
					<div className='bg-[#0f172a] w-full max-w-md py-4 px-4 rounded-xl shadow-lg flex flex-col items-center gap-4'>
						<h2 className='text-xl font-bold text-white text-center'>{t('cta_dark_title')}</h2>
						<form onSubmit={handleSubmit} className='flex flex-col gap-2 w-full items-center'>
							<Input
								type='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder={t('cta_dark_placeholder')}
								className='px-4 py-2 border border-white/20 rounded-md text-white bg-white focus:border-white w-60'
							/>
							<Button
								type='submit'
								disabled={loading}
								className='bg-primary text-white font-semibold px-4 py-2 rounded-md w-60'
							>
								{loading ? t('cta_dark_loading') : t('cta_dark_button_text')}
							</Button>
						</form>
						{message && <p className='mt-2 text-sm text-white'>{message}</p>}
					</div>
				</div>
			</div>

			{/* Desktop Version: displayed on medium screens and above */}
			<div className='hidden md:block'>
				<div className='w-full flex justify-center z-10 relative'>
					<div className='bg-[#0f172a] w-full max-w-[1200px] mx-4 py-8 px-6 rounded-xl shadow-lg flex flex-col items-center gap-6'>
						<h2 className='text-2xl font-bold text-white text-center'>{t('cta_dark_title')}</h2>
						<form onSubmit={handleSubmit} className='flex flex-row items-center gap-2'>
							<Input
								type='email'
								placeholder={t('cta_dark_placeholder')}
								className='bg-white px-4 py-2 border-none border-[#1B1B1B]/20 rounded-md text-black outline-none focus:border-black w-60'
							/>
							<Button className='bg-primary text-white font-semibold px-4 py-2 rounded-md'>
								{loading ? t('cta_dark_loading') : t('cta_dark_button_text')}
							</Button>
						</form>
						{message && <p className='mt-2 text-sm text-white'>{message}</p>}
					</div>
				</div>
			</div>
		</>
	)
}
