'use client'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { addToWaitlist } from '@/services/api/waitlist'
import Image from 'next/image'
import type React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Hero() {
	const { t } = useTranslation('hero')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setLoading(true)
		try {
			const response = await addToWaitlist(email)
			setMessage(response.message)
			setEmail('')
		} catch (error: any) {
			setMessage(error.message)
		} finally {
			setLoading(false)
		}
	}

	// Extraímos os bullet points do JSON
	const bulletPointsRaw = t('bullet_points', { returnObjects: true })
	const bulletPoints = Array.isArray(bulletPointsRaw) ? bulletPointsRaw : []

	return (
		<>
			{/* Mobile Version */}
			<div className='block md:hidden'>
				<section className='relative w-full'>
					<div
						className='w-full bg-cover bg-center pt-16 pb-12 bg-black/30'
						style={{ backgroundImage: `url('/assets/woman.webp')` }}
					>
						<div className='absolute inset-0' />
						<div className='relative container mx-auto text-center text-white px-4'>
							<h1 className='text-3xl font-bold mb-4'>{t('main_title')}</h1>
							<p className='text-xl mb-4 font-semibold'>{t('subtitle')}</p>
							<form onSubmit={handleSubmit} className='flex flex-col items-center gap-2'>
								<Input
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder={t('email_placeholder')}
									className='bg-white px-4 py-2 border-none rounded-md text-black outline-none focus:border-black w-60 h-10'
								/>
								<Button type='submit' size='lg' className='text-xl h-10 w-60' disabled={loading}>
									{loading ? t('loading_text') : t('button_text')}
								</Button>
							</form>
							{message && <p className='mt-2 text-sm text-white'>{message}</p>}
						</div>
					</div>
					<div className='py-6 bg-primary m-2 rounded-md -mt-8 relative z-10'>
						<h2 className='text-xl text-white text-center px-4'>{t('orange_block_text')}</h2>
					</div>
				</section>
			</div>

			{/* Desktop Version */}
			<div className='hidden md:block'>
				<section className='relative w-full min-h-screen'>
					<div
						className='relative w-full bg-cover bg-center pt-24 pb-16 md:pb-24'
						style={{ backgroundImage: `url('/assets/woman.webp')`, backgroundSize: 'cover' }}
					>
						<div className='absolute inset-0 bg-black/40' />
						<div className='container mx-auto relative z-10'>
							<div className='flex flex-col md:flex-row items-center'>
								<div className='md:w-6/12'>
									<h1 className='md:text-6xl font-bold text-primary-foreground w-full md:w-[36rem]'>
										{t('main_title')}
									</h1>
									<p className='text-3xl flex flex-col gap-1 w-full my-3'>
										<span className='text-primary-foreground'>{t('subtitle')}</span>
									</p>
									<form onSubmit={handleSubmit} className='flex flex-row gap-2 mt-4 items-center md:w-[36rem]'>
										<Input
											type='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder={t('email_placeholder')}
											className='bg-white h-12 w-60 rounded-md text-black outline-none focus:border-black'
										/>
										<Button type='submit' size='lg' className='text-xl w-48 h-12' disabled={loading}>
											{loading ? t('loading_text') : t('button_text')}
										</Button>
									</form>
									{message && <p className='mt-2 text-sm text-white'>{message}</p>}
								</div>
								<div className='md:w-1/2' />
							</div>
						</div>
						<div className='relative z-10 container pr-5'>
							<p className='text-5xl font-bold text-primary-foreground text-right'>
								<span className='text-8xl md:text-9xl'>Build</span>
								<span className='text-12xl md:text-11xl font-extrabold'>UP</span>
							</p>
						</div>
					</div>
					<div className='relative z-10 -mt-[24px]'>
						<div className='absolute left-0 top-0 bg-primary py-12 rounded-tr-[24px] rounded-br-[24px] w-[50vw] h-full' />
						<div className='relative container py-12'>
							<h2 className='whitespace-normal text-white text-4xl lg:text-4xl xl:text-4xl leading-tight max-w-[calc(50vw-2rem)] md:w-6/12 pr-6'>
								{t('orange_block_text')}
							</h2>
						</div>
					</div>
					<div className='relative flex flex-col lg:flex-row w-full min-h-[600px] mb-10 -mt-16 container'>
						<div className='relative flex flex-col w-full lg:w-[55%] z-10 mt-8'>
							<div className='my-36'>
								<ul className='space-y-6 text-2xl'>
									{bulletPoints.map((bp) => (
										<li key={bp} className='flex items-center gap-4'>
											<Image src='/assets/bullet-point.svg' alt='icon' width={24} height={24} />
											<span className='text-gray-800'>{bp}</span>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className='hidden lg:block relative w-[50%]'>
							<div className='absolute -right-6 bottom-0 -top-[40%] translate-y-[20%] w-[380px] h-[720px] z-10'>
								<Image src='/assets/mockup-2.webp' alt='App main screen' fill className='object-contain' />
							</div>
							<div className='absolute right-[40%] bottom-0 -top-[20%] translate-y-[21%] w-[300px] h-[640px] z-20'>
								<Image src='/assets/mockup-1.webp' alt='App statistics' fill className='object-contain' />
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
