'use client'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function BlogSection() {
	const { t } = useTranslation('landing_page')

	return (
		<>
			{/* Mobile Version: displayed on small screens */}
			<div className='block md:hidden text-text-primary'>
				<section className='w-full'>
					{/* Dark CTA */}
					<div className='w-full flex justify-center relative -mb-8 px-4'>
						<div className='bg-[#0f172a] w-full max-w-md py-4 px-4 rounded-xl shadow-lg flex flex-col items-center gap-4'>
							<h2 className='text-xl font-bold text-white text-center'>{t('blog_cta_title')}</h2>
							<div className='flex flex-col gap-2 w-full items-center'>
								<Link href='https://buildupfit.com/blog' target='_blank' rel='noopener noreferrer'>
									<Button className='bg-primary text-white font-semibold px-4 py-2 rounded-md w-70'>
										{t('blog_cta_button')}
									</Button>
								</Link>
							</div>
						</div>
					</div>

					{/* Cards Section */}
					<div className='bg-primary pt-12 pb-8 px-4'>
						<div className='max-w-md mx-auto grid grid-cols-1 gap-4'>
							{/* Card 1 */}
							<Link href='https://buildupfit.com/posts/9'>
								<div className='bg-white rounded-lg p-4 shadow-lg'>
									<div className='w-full h-40 relative'>
										<Image
											src='/assets/nandro.webp'
											alt={t('blog_card1_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card1_badge')}</Badge>
									<h3 className='mt-2 text-lg font-bold'>{t('blog_card1_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card1_text')}</p>
								</div>
							</Link>
							{/* Card 2 */}
							<Link href='https://buildupfit.com/posts/4'>
								<div className='bg-white rounded-lg p-4 shadow-lg'>
									<div className='w-full h-40 relative'>
										<Image
											src='/assets/wellness.webp'
											alt={t('blog_card2_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card2_badge')}</Badge>
									<h3 className='mt-2 text-lg font-bold'>{t('blog_card2_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card2_text')}</p>
								</div>
							</Link>
							{/* Card 3 */}
							<Link href='https://buildupfit.com/posts/16'>
								<div className='bg-white rounded-lg p-4 shadow-lg'>
									<div className='w-full h-40 relative'>
										<Image
											src='/assets/arnold.webp'
											alt={t('blog_card3_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card3_badge')}</Badge>
									<h3 className='mt-2 text-lg font-bold'>{t('blog_card3_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card3_text')}</p>
								</div>
							</Link>
						</div>
					</div>
				</section>
			</div>

			{/* Desktop Version: displayed on medium screens and above */}
			<div className='hidden md:block'>
				<section className='relative w-full'>
					{/* Dark CTA - overlayed on the orange background */}
					<div className='w-full flex justify-center -mb-[80px] z-10 relative'>
						<div className='bg-[#0f172a] w-full max-w-[1200px] mx-4 py-8 px-6 rounded-xl shadow-lg flex flex-col items-center gap-6'>
							{/* CTA Title */}
							<h2 className='text-3xl font-bold text-white text-center'>{t('blog_cta_title')}</h2>
							{/* Button */}
							<div className='flex flex-row items-center gap-2'>
								<Link href='https://buildupfit.com/blog' target='_blank' rel='noopener noreferrer'>
									<Button className='bg-primary text-white font-semibold px-4 py-2 rounded-md w-70'>
										{t('blog_cta_button')}
									</Button>
								</Link>
							</div>
						</div>
					</div>

					{/* Orange section with blog cards */}
					<div className='bg-primary pt-[100px] pb-16 px-4'>
						<div className='max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-3 gap-8'>
							{/* Card 1 */}
							<Link href='https://buildupfit.com/posts/9'>
								<div className='bg-white rounded-lg p-4 shadow-lg'>
									<div className='w-full h-[200px] relative'>
										<Image
											src='/assets/nandro.webp'
											alt={t('blog_card1_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card1_badge')}</Badge>
									<h3 className='mt-2 text-xl font-bold text-text-primary'>{t('blog_card1_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card1_text')}</p>
								</div>
							</Link>
							{/* Card 2 */}
							<Link href='https://buildupfit.com/posts/4'>
								<div className='bg-white rounded-lg shadow-lg p-4'>
									<div className='w-full h-[200px] relative'>
										<Image
											src='/assets/wellness.webp'
											alt={t('blog_card2_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card2_badge')}</Badge>
									<h3 className='mt-2 text-xl font-bold text-text-primary'>{t('blog_card2_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card2_text')}</p>
								</div>
							</Link>
							{/* Card 3 */}
							<Link href='https://buildupfit.com/posts/16'>
								<div className='bg-white rounded-lg shadow-lg p-4'>
									<div className='w-full h-[200px] relative'>
										<Image
											src='/assets/arnold.webp'
											alt={t('blog_card3_alt')}
											fill
											className='object-cover rounded-md'
										/>
									</div>
									<Badge className='mt-4'>{t('blog_card3_badge')}</Badge>
									<h3 className='mt-2 text-xl font-bold text-text-primary'>{t('blog_card3_title')}</h3>
									<p className='mt-1 text-sm text-gray-700'>{t('blog_card3_text')}</p>
								</div>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
