'use client'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Numbers() {
	const { t } = useTranslation('landing_page')

	return (
		<>
			{/* Mobile Version: displayed on small screens */}
			<div className='block md:hidden'>
				<section className='w-full container mx-auto mb-8 px-4'>
					<div className='grid grid-cols-1 gap-y-6 text-center'>
						<div>
							<div className='text-4xl font-black text-primary text-left'>{t('numbers.mobile.item1_number')}</div>
							<h3 className='text-2xl font-extrabold text-gray-500'>{t('numbers.mobile.item1_text')}</h3>
						</div>
						<div>
							<div className='text-4xl font-black text-primary text-left'>{t('numbers.mobile.item2_number')}</div>
							<h3 className='text-2xl font-extrabold text-gray-500'>{t('numbers.mobile.item2_text')}</h3>
						</div>
						<div>
							<div className='text-4xl font-black text-primary text-left'>{t('numbers.mobile.item3_number')}</div>
							<h3 className='text-2xl font-extrabold text-gray-500'>{t('numbers.mobile.item3_text')}</h3>
						</div>
					</div>
				</section>
			</div>

			{/* Desktop Version: displayed on medium screens and above */}
			<div className='hidden md:block'>
				<section className='w-full mx-auto container mb-8 items-center justify-items-center pt-[460px] pb-3'>
					<div className='grid grid-cols-[auto_1fr] gap-y-10'>
						<div className='text-[130px] font-black text-primary leading-none'>{t('numbers.desktop.item1_number')}</div>
						<h3 className='text-[60px] font-extrabold text-gray-500 leading-tight ml-2'>
							{t('numbers.desktop.item1_text')}
						</h3>

						<div className='text-[130px] font-black text-primary leading-none'>{t('numbers.desktop.item2_number')}</div>
						<h3 className='text-[60px] font-extrabold text-gray-500 leading-tight ml-2'>
							{t('numbers.desktop.item2_text')}
						</h3>

						<div className='text-[130px] font-black text-primary leading-none'>{t('numbers.desktop.item3_number')}</div>
						<h3 className='text-[60px] font-extrabold text-gray-500 leading-tight ml-2'>
							{t('numbers.desktop.item3_text')}
						</h3>
					</div>
				</section>
			</div>
		</>
	)
}
