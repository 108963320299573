'use client'
import { Button } from '@/components/ui/button'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PricingSection() {
	const { t } = useTranslation('landing_page')

	// Seção geral
	const sectionTitle = t('pricing.section_title')

	// Dados do grupo ATLETA
	const athleteGroupTitle = t('pricing.athlete.group_title')
	const athleteBasicTitle = t('pricing.athlete.basic.title')
	const athleteBasicPrice = t('pricing.athlete.basic.price')
	const athleteBasicBullets = t('pricing.athlete.basic.bullets', { returnObjects: true }) as string[]
	const athletePlusTitle = t('pricing.athlete.plus.title')
	const athletePlusPrice = t('pricing.athlete.plus.price')
	const athletePlusBullets = t('pricing.athlete.plus.bullets', { returnObjects: true }) as string[]

	// Dados do grupo COACH
	const coachGroupTitle = t('pricing.coach.group_title')
	const coachBasicTitle = t('pricing.coach.basic.title')
	const coachBasicPrice = t('pricing.coach.basic.price')
	const coachBasicBulletsMobile = t('pricing.coach.basic.bullets_mobile', { returnObjects: true }) as string[]
	const coachBasicBulletsDesktop = t('pricing.coach.basic.bullets_desktop', { returnObjects: true }) as string[]
	const coachPlusTitle = t('pricing.coach.plus.title')
	const coachPlusPrice = t('pricing.coach.plus.price')
	const coachPlusBullets = t('pricing.coach.plus.bullets', { returnObjects: true }) as string[]

	return (
		<>
			{/* Mobile Version */}
			<div className='block md:hidden text-text-primary'>
				<section className='w-full max-w-md mx-auto px-4 py-8 flex flex-col gap-8'>
					{/* General Title */}
					<div className='flex justify-center'>
						<h2 className='text-3xl font-black text-gray-400'>{sectionTitle}</h2>
					</div>

					{/* Athlete Group */}
					<div className='flex flex-col items-center gap-4'>
						<h3 className='text-2xl font-bold'>{athleteGroupTitle}</h3>
						{/* Basic Card */}
						<div className='w-full bg-[#e5e7eb] p-4 rounded-lg border border-primary flex flex-col gap-2'>
							<h4 className='text-center text-lg font-bold'>{athleteBasicTitle}</h4>
							<div className='mx-auto bg-[#d1d5db] text-[#0f172a] px-4 py-2 rounded-md font-semibold'>
								{athleteBasicPrice}
							</div>
							<ul className='list-disc ml-4 space-y-1 text-sm'>
								{athleteBasicBullets.map((bullet) => (
									<li key={bullet}>{bullet}</li>
								))}
							</ul>
						</div>
						{/* Plus Card */}
						<div className='w-full bg-[#0f172a] text-white p-4 rounded-lg border border-primary flex flex-col gap-2'>
							<h4 className='text-center text-lg font-bold'>{athletePlusTitle}</h4>
							<div className='mx-auto bg-primary text-white px-4 py-2 rounded-md font-semibold'>{athletePlusPrice}</div>
							<ul className='list-disc ml-4 space-y-1 text-sm'>
								{athletePlusBullets.map((bullet) => (
									<li key={bullet}>{bullet}</li>
								))}
							</ul>
						</div>
					</div>

					{/* Coach Group */}
					<div className='flex flex-col items-center gap-4 mt-8'>
						<h3 className='text-2xl font-bold'>{coachGroupTitle}</h3>
						{/* Basic Card (Mobile) */}
						<div className='w-full bg-[#e5e7eb] p-4 rounded-lg border border-primary flex flex-col gap-2'>
							<h4 className='text-center text-lg font-bold'>{coachBasicTitle}</h4>
							<div className='mx-auto bg-[#d1d5db] text-[#0f172a] px-4 py-2 rounded-md font-semibold'>
								{coachBasicPrice}
							</div>
							<ul className='list-disc ml-4 space-y-1 text-sm'>
								{(coachBasicBulletsMobile || []).map((bullet) => (
									<li key={bullet}>{bullet}</li>
								))}
							</ul>
						</div>
						{/* Plus Card */}
						<div className='w-full bg-[#0f172a] text-white p-4 rounded-lg border border-primary flex flex-col gap-2'>
							<h4 className='text-center text-lg font-bold'>{coachPlusTitle}</h4>
							<div className='mx-auto bg-primary text-white px-4 py-2 rounded-md font-semibold'>{coachPlusPrice}</div>
							<ul className='list-disc ml-4 space-y-1 text-sm'>
								{coachPlusBullets.map((bullet) => (
									<li key={bullet}>{bullet}</li>
								))}
							</ul>
						</div>
					</div>
				</section>
			</div>

			{/* Desktop Version */}
			<div className='hidden md:block text-text-primary'>
				<section className='w-full max-w-[1200px] mx-auto px-4 py-12 mb-3 flex flex-col gap-12'>
					{/* General Title */}
					<div className='flex justify-center'>
						<h2 className='text-[96px] font-black leading-none text-gray-400'>{sectionTitle}</h2>
					</div>

					{/* Athlete Group */}
					<div className='flex items-center gap-4 mb-3'>
						{/* Rotated "ATLETA" text */}
						<div className='flex-shrink-0'>
							<span className='block text-[96px] font-black leading-none text-gray-400 -rotate-90 origin-center mx-2'>
								{athleteGroupTitle}
							</span>
						</div>
						{/* Athlete Price Cards */}
						<div className='flex flex-row gap-8'>
							{/* Basic Card */}
							<div className='w-[300px] bg-[#e5e7eb] p-8 rounded-tr-xl rounded-br-xl border-t-2 border-r-2 border-primary flex flex-col gap-6'>
								<h3 className='text-center text-lg font-bold tracking-wide'>{athleteBasicTitle}</h3>
								<div className='mx-auto bg-[#d1d5db] text-[#0f172a] px-4 py-2 rounded-md font-semibold'>
									{athleteBasicPrice}
								</div>
								<div className='flex flex-col gap-3'>
									{athleteBasicBullets.map((bullet) => (
										<div key={bullet} className='flex items-center gap-2'>
											<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
											<span>{bullet}</span>
										</div>
									))}
								</div>
							</div>
							{/* Plus Card */}
							<div className='w-[300px] bg-[#0f172a] text-white p-8 rounded-tr-xl rounded-br-xl border-t-2 border-r-2 border-primary flex flex-col gap-6'>
								<h3 className='text-center text-lg font-bold tracking-wide'>{athletePlusTitle}</h3>
								<div className='mx-auto bg-primary text-white px-4 py-2 rounded-md font-semibold'>
									{athletePlusPrice}
								</div>
								<div className='flex flex-col gap-3'>
									{athletePlusBullets.map((bullet) => (
										<div key={bullet} className='flex items-center gap-2'>
											<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
											<span>{bullet}</span>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					{/* Coach Group */}
					<div className='flex items-center gap-4'>
						{/* Rotated "COACH" text */}
						<div className='flex-shrink-0'>
							<span className='block text-[96px] font-black leading-none text-gray-400 -rotate-90 origin-center mx-4'>
								{coachGroupTitle}
							</span>
						</div>
						{/* Coach Price Cards */}
						<div className='flex flex-row gap-8'>
							{/* Basic Card */}
							<div className='w-[300px] bg-[#e5e7eb] p-8 rounded-tr-xl rounded-br-xl border-t-2 border-r-2 border-primary flex flex-col gap-6'>
								<h3 className='text-center text-lg font-bold tracking-wide'>{coachBasicTitle}</h3>
								<div className='mx-auto bg-[#d1d5db] text-[#0f172a] px-4 py-2 rounded-md font-semibold'>
									{coachBasicPrice}
								</div>
								<div className='flex flex-col gap-3'>
									{(coachBasicBulletsDesktop || []).map((bullet) => (
										<div key={bullet} className='flex items-center gap-2'>
											<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
											<span>{bullet}</span>
										</div>
									))}
								</div>
							</div>
							{/* Plus Card */}
							<div className='w-[300px] bg-[#0f172a] text-white p-8 rounded-tr-xl rounded-br-xl border-t-2 border-r-2 border-primary flex flex-col gap-6'>
								<h3 className='text-center text-lg font-bold tracking-wide'>{coachPlusTitle}</h3>
								<div className='mx-auto bg-primary text-white px-4 py-2 rounded-md font-semibold'>{coachPlusPrice}</div>
								<div className='flex flex-col gap-3'>
									{coachPlusBullets.map((bullet) => (
										<div key={bullet} className='flex items-center gap-2'>
											<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
											<span>{bullet}</span>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
