'use client'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface FAQItem {
	question: string
	answer: string
}

export default function FAQ() {
	const { t } = useTranslation('faq')
	const faqItems = t('faq_items', { returnObjects: true }) as FAQItem[]

	return (
		<>
			{/* Mobile Version */}
			<div id='faq-section-mobile' className='block md:hidden'>
				<section className='w-full max-w-4xl mx-auto px-4 py-12'>
					<h2 className='text-4xl font-bold text-gray-600 mb-8'>{t('faq_title')}</h2>
					<Accordion type='single' collapsible className='flex flex-col gap-4'>
						{faqItems.map((item) => (
							<AccordionItem
								key={item.question}
								value={item.question}
								className='border-t border-r border-primary rounded-tr-xl rounded-br-xl overflow-hidden border-b-0'
							>
								<AccordionTrigger className='flex items-center gap-2 bg-[#e5e7eb] p-4 text-base font-semibold text-[#0f172a] border-b border-white'>
									<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
									{item.question}
								</AccordionTrigger>
								<AccordionContent className='bg-[#e5e7eb] p-4 text-sm text-[#0f172a]'>{item.answer}</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</section>
			</div>

			{/* Desktop Version */}
			<div id='faq-section' className='hidden md:block'>
				<section className='w-full max-w-4xl mx-auto px-4 my-12'>
					<h2 className='text-4xl font-bold text-gray-600 my-11 pb-2'>{t('faq_title')}</h2>
					<Accordion type='single' collapsible className='flex flex-col gap-4'>
						{faqItems.map((item) => (
							<AccordionItem
								key={item.question}
								value={item.question}
								className='border-t-2 border-r-2 border-primary rounded-tr-xl rounded-br-xl overflow-hidden border-b-0'
							>
								<AccordionTrigger className='flex items-center gap-2 bg-[#e5e7eb] p-4 text-base font-semibold text-[#0f172a] border-b border-white'>
									<Image src='/assets/bullet-point.svg' alt='icon' width={12} height={12} />
									{item.question}
								</AccordionTrigger>
								<AccordionContent className='bg-[#e5e7eb] p-4 text-sm text-[#0f172a]'>{item.answer}</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</section>
			</div>
		</>
	)
}
