'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CTAOrange() {
	const { t } = useTranslation('landing_page')

	return (
		<>
			{/* Mobile Version: displayed on small screens */}
			<div className='block md:hidden'>
				<div className='w-full flex justify-center my-8 px-4'>
					<div className='bg-primary w-full max-w-md py-4 px-4 rounded-xl shadow-lg flex flex-col items-center gap-4'>
						<h2 className='text-xl font-bold text-[#1B1B1B] text-center'>{t('cta_orange_title')}</h2>
						<div className='flex flex-col gap-2 w-full items-center'>
							<Input
								type='email'
								placeholder={t('cta_orange_placeholder')}
								className='bg-white px-4 py-2 border-none border-[#1B1B1B]/20 rounded-md text-black outline-none focus:border-black w-60'
							/>
							<Button className='bg-[#0f172a] text-white font-semibold px-4 py-2 rounded-md w-60'>
								{t('cta_orange_button_text')}
							</Button>
						</div>
					</div>
				</div>
			</div>

			{/* Desktop Version: displayed on medium screens and above */}
			<div className='hidden md:block'>
				<div className='w-full flex justify-center -mb-[80px] z-10 relative'>
					<div className='bg-primary w-full max-w-[1200px] mx-4 py-8 px-6 rounded-xl shadow-lg flex flex-col items-center gap-6'>
						<h2 className='text-2xl font-bold text-[#1B1B1B] text-center'>{t('cta_orange_title')}</h2>
						<div className='flex flex-row items-center gap-2'>
							<Input
								type='email'
								placeholder={t('cta_orange_placeholder')}
								className='bg-white px-4 py-2 border-none border-[#1B1B1B]/20 rounded-md text-black outline-none focus:border-black w-60'
							/>
							<Button className='bg-[#0f172a] text-white font-semibold px-4 py-2 rounded-md'>
								{t('cta_orange_button_text')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
