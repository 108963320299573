'use client'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function AthleteCard() {
	const { t } = useTranslation('landing_page')

	return (
		<>
			{/* Mobile Version: displayed on small screens */}
			<div className='block md:hidden'>
				<section className='w-full flex justify-center p-4'>
					<div className='w-full max-w-md bg-[#d1d5db] rounded-xl p-4 shadow-md'>
						<div className='flex flex-col items-center'>
							{/* Container for image with adjustable maximum size */}
							<div className='w-full max-w-xs'>
								<Image
									src='/assets/mockup-3.webp'
									alt={t('mobile_image_alt')}
									width={200}
									height={333}
									className='rounded object-contain mx-auto'
								/>
							</div>
							<h2 className='text-3xl font-black text-gray-500 mt-4'>{t('athlete_title')}</h2>
							<ul className='list-disc text-base text-[#0f172a] mt-4 ml-6 text-left'>
								<li>{t('bullet_item_1')}</li>
								<li>{t('bullet_item_2')}</li>
								<li>{t('bullet_item_3')}</li>
							</ul>
						</div>
					</div>
				</section>
			</div>

			{/* Desktop Version: displayed on medium screens and above */}
			<div className='hidden md:block'>
				<section className='relative w-full'>
					{/* Card Background */}
					<div className='absolute left-0 top-0 xl:w-[68vw] lg:w-[74vw] md:w-[82vw] w-11/12 h-full min-h-[350px] bg-[#d1d5db] border-t-2 border-r-2 border-primary rounded-tr-xl rounded-br-xl' />
					{/* Centered Container */}
					<div className='relative container top-44'>
						<div className='pr-6 max-w-[calc(50vw-2rem)]'>
							{/* Smartphone Image */}
							<div className='absolute left-8 top-1/2 -translate-y-1/2 w-[250px] h-[500px] z-20'>
								<Image src='/assets/mockup-3.webp' alt={t('desktop_image_alt')} fill className='object-cover' />
							</div>
							{/* Rotated "ATLETA" Text */}
							<span className='absolute left-[230px] top-1/2 -translate-y-1/2 z-10 text-[60px] font-black text-gray-500 -rotate-90 leading-none origin-center'>
								{t('athlete_title')}
							</span>
							{/* Bullet Points */}
							<div className='absolute left-[420px] top-1/2 -translate-y-1/2 z-10 max-w-[330px] md:max-w-[320px] lg:max-w-[340px] xl:max-w-[440px]'>
								<ul className='list-disc text-lg text-[#0f172a] leading-relaxed space-y-3 whitespace-normal break-words'>
									<li>{t('bullet_item_1')}</li>
									<li>{t('bullet_item_2')}</li>
									<li>{t('bullet_item_3')}</li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}
